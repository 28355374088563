<template>
  <ManageUsersGroupsCreateUser
      :UG="UG"
      :accessRoles="accessRoles"
      @save="save({edit: false})"
  />
</template>

<script>
import ManageUsersGroupsCreateUser from "./ManageUsersGroupsCreateUser/ManageUsersGroupsCreateUser";
import {UserGroups} from "../models/UserGroups";
import {SYSTEM_ROLE_USER} from "../../../../../staticData/staticVariables";
import {userGroupsMixin} from "../../../../../mixins/manageUsersMixins/userGroupsMixin";
// import {manageUsersMixin} from "../../../../../mixins/manageUsersMixins/manageUsersMixin";

export default {
  name: "ManageUsersCreate",
  components: {
    ManageUsersGroupsCreateUser,
  },

  mixins: [userGroupsMixin],

  watch: {
    loadUserAuthorizedData() {
      this.checkGroupsCreateAccess()
    }
  },

  data() {
    return {
      UG: new UserGroups(),
      accessRoles: [],
    }
  },

  mounted() {
    this.initUserGroupCreate()

    if (this.loadUserAuthorizedData) {
      this.checkGroupsCreateAccess()
    }

  },

  methods: {

    initUserGroupCreate() {
      return this.$store.dispatch('fetchPermissionsGroups', `?filter[permissionGroupRole.role_id]=${SYSTEM_ROLE_USER.id}`).then(response => {
        this.UG.setPermissions(false)
        this.UG.setPermissions(this.getRespData(response))
      })
    },

    checkGroupsCreateAccess() {
      if (!this.isDeveloper && !this.isAdmin) {
        this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }
    },

  }

}
</script>

<style scoped>

</style>
