// import {User} from "./User";
import {checkValid} from "../../../../globalModels/functions/ModelsValidate";
import {ManagePermissions} from "./ManagePermissions";

export function UserGroups() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    name: '',

    ManagePermissions: new ManagePermissions(),
  }


  this.validation = {
    name: false,
    permissions: false,
  }

  this.validationTranslate = {
    name: '',
    permissions: '',
  }

  this.validationTxt = {
    name: false,
    permissions: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getName = () => {
    return this.data.name
  }
  this.getPermissions = () => {
    return this.data.permissions
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setName = (val) => {
    this.data.name = val
  }
  // this.setPermissions = (val) => {
  //   this.data.permissions = val
  // }

}

/**
 * Global Setters
 */

UserGroups.prototype.setItem = function (data) {
  this.setId(data.id)
  this.setName(data.name)
}

UserGroups.prototype.setPermissionsByUserData = function (permissions) {
  let permissionsObject = {}
  permissions.forEach(item => {
    permissionsObject[item.id] = item.name
  })
  console.log(permissionsObject, 5555555);
  this.data.ManagePermissions.setPermissionsByUserData(permissionsObject)
}


UserGroups.prototype.setPermissions = function (permissions) {
  if (!permissions) {
    this.data.ManagePermissions = new ManagePermissions()
    return
  }
  this.data.ManagePermissions.setManagePermissions(permissions)
}

/**
 * Prepare Data
 */

UserGroups.prototype.prepareSave = function (edit) {
  console.log(edit);
  return {
    'name': this.getName(),
    'permissions': this.data.ManagePermissions.getPermissionsIdsAsObjectsArray(),
  }
}


/**
 * Validations
 */

UserGroups.prototype.validationItem = function (edit) {
  console.log(edit);
  let validationItems = {
    name: this.getName(),
  }


  let validationOptions = {
    name: {type: ['empty']},
  }

  return (this.checkValid(validationItems, validationOptions))
}
