export const userGroupsMixin = {

  methods: {

    save(dataSave) {

      if(!this.UG.validationItem(dataSave?.edit)) return

      let data = this.UG.prepareSave(dataSave?.edit)


      let saveType = 'createManageUsersGroups'
      console.log(saveType);
      if(dataSave?.edit){
        saveType = 'updateManageUsersGroups'
        data = {
          id: this.$route.params.id,
          data: data
        }
      }

      this.$store.dispatch(saveType, data).then(response => {
        response.status = response?.data?.status || response?.response?.status

        switch (response.status) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            if (dataSave?.edit) {
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }
            this.$router.push(this.$store.getters.GET_PATHS.mainSettingsManageUsersLink + this.$store.getters.getManageUsersFilter)
            this.$store.commit('setManageUsersFilter', '')
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },


  }

}
